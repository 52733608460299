

import React from 'react';
 import { FaRegWindowClose } from "react-icons/fa";


function Cards({ id, status, name, onRemove }) {
  const handleRemove = () => {
    onRemove(id);
  };

  return (
    <div className="relative p-4 bg-white shadow-md rounded-lg">
          <button className="absolute top-2 right-2" onClick={handleRemove} >
            <FaRegWindowClose 
             color='red' size={20} />
         </button>
        <h2 className="text-lg font-bold">{name}</h2>
        <p>Status: {status}</p>
      </div>
  );
}

export default Cards;

