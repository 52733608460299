// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Cookies from 'js-cookie';
// import { useNavigate } from "react-router-dom";
// import { FaTimes } from 'react-icons/fa';

// import Loader from '../pages/Loader';
// import { baseUrl } from "../api/api";

// const Dashboard = () => {
//   const [file, setFile] = useState("");
//   const [thumbnail, setThumbnail] = useState("");
//   const [name, setName] = useState("");
//   const [channel,setChannel]=useState("")
//   const [loading, setLoading] = useState(false);
//   const [loggedIn, setLoggedIn] = useState(false);
//   const [acceptedTerms, setAcceptedTerms] = useState(false);
//   const [msisdn,setMsisdn]=useState("")
//   const navigate = useNavigate();


//   const notifyError = (message) => toast.error(message);
//   const notifySuccess = (message) => toast.success(message);

//   const channels = ['Channel 1', 'Channel 2', 'Channel 3'];

//   useEffect(() => {
//     const msisdnCookie = Cookies.get('number');
//     if (msisdnCookie) {
//       setMsisdn(msisdnCookie);
//       console.log(msisdnCookie, "====cookies");
//     }
//   }, []);

//   const handleFileSelect = (e) => {
//     const uploadedFile = e.target.files[0];
//     if (uploadedFile) {
//       const fileURL = URL.createObjectURL(uploadedFile);
//       const video = document.createElement('video');

//       video.preload = 'metadata';
//       video.onloadedmetadata = () => {
//         window.URL.revokeObjectURL(fileURL);
//         if (video.duration > 600) { // 600 seconds = 10 minutes
//           notifyError('Video duration should not be more than 10 minutes.');
//           setFile(null);
//         } else {
//           setFile(uploadedFile);
//         }
//       };

//       video.src = fileURL;
//     }
//   };

//   const handleThumbnailSelect = (e) => {
//     const uploadedThumbnail = e.target.files[0];
//     setThumbnail(uploadedThumbnail);
//   };

//   const handleClose=()=>{
//     navigate('/')
//   }
//   const handleFileUpload = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     if (!file || name.trim() === "" || !acceptedTerms) {
//       setLoading(false);
//       notifyError("Please fill all fields and accept the terms.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("name", name);
//     formData.append("file", file);
//     formData.append("msisdn", msisdn);

//     if (thumbnail) {
//       formData.append("thumbnail", thumbnail);
//     }
// //${baseUrl}
//     try {
//       await axios.post(`${baseUrl}/upload`, formData);
//       setFile("");
//       setThumbnail("");
//       setName("");
//       setLoading(false);
//       notifySuccess("Upload successful!");
//     } catch (error) {
//       console.error("Error uploading:", error);
//       setLoading(false);
//       notifyError("Failed to upload. Please try again later.");
//     }
//   };

//   const handleCheckboxChange = (event) => {
//     setAcceptedTerms(event.target.checked);
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="min-h-screen flex items-center justify-center">
//         <div className="mx-auto w-full mb-8 max-w-[550px] bg-white shadow-blue-300 rounded-lg lg:mt-8 shadow-2xl">
//         {/* <button onClick={handleClose} className="absolute top-4 right-4 text-red-500">
//             <FaTimes size={20} />
//           </button> */}
//           <form className="py-4 px-9 relative" onSubmit={handleFileUpload}>
//           <button onClick={handleClose} className="absolute top-2 right-2 text-red-500">
//             <FaTimes size={25} />
//           </button>
//             <div className="">
              
//               <label className="mb-4 block text-xl font-semibold text-[#07074D]">
//                 Upload Video File
//               </label>
//               <div className="mb-8">
//                 <input
//                   type="file"
//                   name="file"
//                   id="file"
//                   className="sr-only"
//                   onChange={handleFileSelect}
//                 />
//                 <label
//                   htmlFor="file"
//                   className="relative flex  max-h-[150px] items-center justify-center rounded-md border 
//                   border-dashed border-[#454242] p-12 text-center"
//                 >
//                   <div>
//                     <span className="mb-2 block text-xl font-semibold text-[#07074D]">
//                       Drop files here
//                     </span>
//                     <span className="mb-2 block text-base font-medium text-[#6B7280]">
//                       Or
//                     </span>
//                     <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base
//                      font-medium text-[#07074D]">
//                       Browse
//                     </span>
//                   </div>
//                 </label>
//                 {file && (
//                   <div className="mt-4 flex justify-center">
//                     <p className="font-semibold">Selected File: </p> <p className="w-auto overflow-x-auto">{file.name}</p>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="mb-1">
//               <label className="mb-2 block text-xl font-semibold text-[#07074D]">
//                 Upload Thumbnail
//               </label>
//               <input
//                 type="file"
//                 onChange={handleThumbnailSelect}
//                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
//                 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//               {thumbnail && (
//                 <div className="mt-4 flex justify-center">
//                   <p className="font-semibold">Selected Thumbnail:</p><p className="w-auto overflow-x-auto">{thumbnail.name}</p>
//                 </div>
//               )}
//             </div>
           
//             <div className="mb-2 pt-4">
//               <label className="mb-1 block text-xl font-semibold text-[#07074D]">
//                 Name
//               </label>
//               <input
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
//                 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               /> 
//             </div>

//             <div className="mb-2 pt-4">
//               <label className="mb-1 block text-xl font-semibold text-[#07074D]">
//                Channel Name
//               </label>
             
//               <select className="border border-gray-300 py-2 px-4 rounded-lg">
//                 <option>select channel</option>
//                 <option>Dance Space</option>
//                 <option>Alternative Sports</option>
//                 <option>Daily Conversation</option>
//                 <option>Lip Sync</option>
//                 <option>The Barstool</option>

//               </select>
//             </div>
//             <div className="mb-1 pt-1">
//               <input
//                 type="checkbox"
//                 checked={acceptedTerms}
//                 onChange={handleCheckboxChange}
//               />
//               <label className="ml-2">
//                 Kindly confirm that you have the rights to distribute and use the provided content
//               </label>
//             </div>
//             <div className="pt-4">
//               <button
//                 type="submit"
//                 className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 
//                 px-8 text-center text-base font-semibold text-white outline-none"
//                 disabled={!acceptedTerms}
//               >
//                 {loading ? <Loader /> : "Upload"}
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Dashboard;


import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { FaTimes } from 'react-icons/fa';

import Loader from '../pages/Loader';
import { baseUrl } from "../api/api";

const Dashboard = () => {
  const [file, setFile] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [name, setName] = useState("");
  const [channel, setChannel] = useState(""); // Add channel state
  console.log(channel,"channel")
  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [msisdn, setMsisdn] = useState("");
  const navigate = useNavigate();

  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);

  const channels = ['Dance Space', 'Alternative Sports', 'Daily Conversation', 'Lip_sync', 'The_Barstool'];

  useEffect(() => {
    const msisdnCookie = Cookies.get('number');
    if (msisdnCookie) {
      setMsisdn(msisdnCookie);
    }
  }, []);

  const handleFileSelect = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      const fileURL = URL.createObjectURL(uploadedFile);
      const video = document.createElement('video');

      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(fileURL);
        if (video.duration > 600) {
          notifyError('Video duration should not be more than 10 minutes.');
          setFile(null);
        } else {
          setFile(uploadedFile);
        }
      };

      video.src = fileURL;
    }
  };

  const handleThumbnailSelect = (e) => {
    const uploadedThumbnail = e.target.files[0];
    setThumbnail(uploadedThumbnail);
  };

  const handleClose = () => {
    navigate('/');
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!file || name.trim() === "" || channel.trim() === "" || !acceptedTerms) {
      setLoading(false);
      notifyError("Please fill all fields and accept the terms.");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    formData.append("msisdn", msisdn);
    formData.append("channel", channel); 

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    try {
      await axios.post(`${baseUrl}/upload`, formData);
      setFile("");
      setThumbnail("");
      setName("");
      setLoading(false);
      notifySuccess("Upload successful!");
    } catch (error) {
      console.error("Error uploading:", error);
      setLoading(false);
      notifyError("Failed to upload. Please try again later.");
    }
  };

  const handleCheckboxChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen flex items-center justify-center">
        <div className="mx-auto w-full mb-8 max-w-[550px] bg-white shadow-blue-300 rounded-lg lg:mt-8 shadow-2xl">
          <form className="py-4 px-9 relative" onSubmit={handleFileUpload}>
            <button onClick={handleClose} className="absolute top-2 right-2 text-red-500">
              <FaTimes size={25} />
            </button>
            <div>
              <label className="mb-4 block text-xl font-semibold text-[#07074D]">
                Upload Video File
              </label>
              <div className="mb-8">
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="sr-only"
                  onChange={handleFileSelect}
                />
                <label
                  htmlFor="file"
                  className="relative flex max-h-[150px] items-center justify-center rounded-md border border-dashed border-[#454242] p-12 text-center"
                >
                  <div>
                    <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                      Drop files here
                    </span>
                    <span className="mb-2 block text-base font-medium text-[#6B7280]">
                      Or
                    </span>
                    <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                      Browse
                    </span>
                  </div>
                </label>
                {file && (
                  <div className="mt-4 flex justify-center">
                    <p className="font-semibold">Selected File: </p> <p className="w-auto overflow-x-auto">{file.name}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-1">
              <label className="mb-2 block text-xl font-semibold text-[#07074D]">
                Upload Thumbnail
              </label>
              <input
                type="file"
                onChange={handleThumbnailSelect}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {thumbnail && (
                <div className="mt-4 flex justify-center">
                  <p className="font-semibold">Selected Thumbnail:</p><p className="w-auto overflow-x-auto">{thumbnail.name}</p>
                </div>
              )}
            </div>
            <div className="mb-2 pt-4">
              <label className="mb-1 block text-xl font-semibold text-[#07074D]">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mb-2 pt-4">
              <label className="mb-1 block text-xl font-semibold text-[#07074D]">
                Channel Name
              </label>
              <select value={channel} onChange={(e) => setChannel(e.target.value)} className="border border-gray-300 py-2 px-4 rounded-lg">
                <option value="">Select channel</option>
                {channels.map((ch) => (
                  <option key={ch} value={ch}>{ch}</option>
                ))}
              </select>
            </div>
            <div className="mb-1 pt-1">
              <input
                type="checkbox"
                checked={acceptedTerms}
                onChange={handleCheckboxChange}
              />
              <label className="ml-2">
                Kindly confirm that you have the rights to distribute and use the provided content
              </label>
            </div>
            <div className="pt-4">
              <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                disabled={!acceptedTerms}
              >
                {loading ? <Loader /> : "Upload"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Dashboard;



