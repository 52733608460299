import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'js-cookie'

const DoiRedirect = () => {
    const [loading, setLoading]  = useState(true);
    const [q] = useSearchParams();
    const nav = useNavigate();
    let msisdn = q.get("msisdn");
    let subscription_id = q.get("subscription_id");
    let ext_ref = q.get("ext_ref");
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
   
    // const subdomain='diskichat'
    
    axios.get(
        `https://callback.bubblebobble.co.za/api/subscribe/check-sub?msisdn=${msisdn}&subscription_id=${subscription_id}`
    ).then(resp => {
        const data = resp.data;
        console.log("check-data =>", data);
        setLoading(false)
        if(data.result == 1) {
            Cookies.set('number',msisdn,{expires:1})
    
          nav('/') 
        }

        if(data.result == 0) {
    
            nav('/login') 
          }
    
    }).catch(e => {
        setLoading(false);
        nav("/login")
    })
    if(loading) return <h1 className='text-lg bg-[#C7A4E9] text-black'>Redirecting....</h1>
}

export default DoiRedirect
