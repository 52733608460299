import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../pages/Loader'; // Replace with your loader component
import Card from '../pages/Card'; // Ensure you import your Card component
import { fetchChannelVideosStart, fetchChannelVideosSuccess, fetchChannelVideosFailure } from '../slice/channelSlice'; // Adjust import as per your slice setup
import { baseUrl } from '../api/api';
import Navbar from '../pages/Navbar';
import Cookies from 'js-cookie'

const ChannelVideos = () => {
  const dispatch = useDispatch();
  const { channelName } = useParams();


  

  // Ensure correct state slice access
  const videos = useSelector((state) => state.channelVideos?.videos);
  console.log(videos,"state")
  const loading = useSelector((state) => state.channelVideos?.loading);
  const error = useSelector((state) => state.channelVideos?.error);

  useEffect(() => {
    const fetchVideos = async () => {
      dispatch(fetchChannelVideosStart());
      try {
        const response = await axios.post(`${baseUrl}/api/channel`, 
            {channel_name: channelName
        }
    );
        console.log(response, "response");
        Cookies.set('channel_name',channelName)
        dispatch(fetchChannelVideosSuccess(response.data));
      } catch (err) {
        dispatch(fetchChannelVideosFailure(err.message));
      }
    };

    fetchVideos();
  }, [dispatch, channelName]);

  if (loading) {
    return (
    <div className='flex justify-center items-center h-screen bg-[#e8dcf4]'>
        <Loader/>
        </div>);
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className='pb-6 h-full lg:h-full md:h-full'>
        <Navbar/>
      <h1 className='lg:mt-6 lg:mx-10 lg:px-6 lg:text-2xl text-xl mx-6 px-4 mt-10 text-black bg-[#e8dcf4] rounded-t-lg lg:pt-4 font-semibold '>{channelName}</h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 gap-3 shadow-lg bg-[#e8dcf4] lg:mx-10 lg:px-6 rounded-b-lg pb-4 px-2 mx-6">
        {videos && videos.map(video => (
          <Card
            key={video.id}
            image_url={video.image_url}
            id={video.id}
            name={video.name}
          />
        ))}
      </div>
    </div>
  );
};

export default ChannelVideos;
