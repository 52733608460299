import { configureStore } from '@reduxjs/toolkit';
import videoReducer from '../slice/videoSlice'
import channelVideosReducer from '../slice/channelSlice'; // Adjust the path as necessary
import categoryReducer from '../slice/categorySlice'; // Adjust the path based on your directory structure
import similarVideoReducer from '../slice/similarVideoSlice'
// import ChannelVideos from '../components/ChannelVideos';

const store=configureStore({
    reducer:{
        videos:videoReducer,
        channelVideos: channelVideosReducer,
        categories: categoryReducer,
        similarVideos:similarVideoReducer

    }
});

export default store
