import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videos: [],
  loading: false,
  error: null,
};

const channelSlice = createSlice({
  name: 'channelVideos',
  initialState,
  reducers: {
    fetchChannelVideosStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchChannelVideosSuccess(state, action) {
      state.loading = false;
      state.videos = action.payload;
    },
    fetchChannelVideosFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchChannelVideosStart,
  fetchChannelVideosSuccess,
  fetchChannelVideosFailure,
} = channelSlice.actions;

export default channelSlice.reducer;
