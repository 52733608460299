
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { baseUrl } from '../api/api';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from '../pages/Loader'
import { useNavigate } from 'react-router-dom';

const Carousel = () => {
  const [banners, setBanners] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/channel_banner`);
        setBanners(response.data.message);
        setIsLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching banners:', error);
        setIsLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchBanners();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleBannerClick = (channelName) => {
    navigate(`/channel/${channelName}`);
  };

  return (
    <>
      <h1 className='lg:mt-6 lg:mx-4 lg:px-6 lg:text-2xl text-xl mx-6 px-4 mt-2 text-black lg:pt-4 font-semibold'>CHANNELS</h1>

      <div className="carousel-container lg:mx-10 mx-6 lg:px-6 lg:py-6 rounded shadow-2xl shadow-fuchsia-200 bg-white/50 mt-2 h-[300px]">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader/>
          </div>
        ) : (
          <Slider {...settings}>
            {banners.map((banner) => (
              <div key={banner.id} className="relative p-2">
                <div
                  onClick={() => handleBannerClick(banner.channel_name)}
                  className="block w-full h-full cursor-pointer"
                >
                  <img
                    src={banner.channel_banner}
                    alt={`channel-banner-${banner.channel_name}`}
                    className="lg:w-full lg:h-full w-full h-full object-cover"
                    style={{ height: '250px' }}
                  />
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};

export default Carousel;
