

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from './Card'; 
import {
  fetchSimilarVideosStart,
  fetchSimilarVideosSuccess,
  fetchSimilarVideosFailure,
} from '../slice/similarVideoSlice';
import axios from 'axios';
import { baseUrl } from '../api/api';
import Cookies from 'js-cookie';
import Loader from './Loader';

function SimilarVideos() {
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const dispatch = useDispatch();
  const similarVideos = useSelector((state) => state.similarVideos.videos);
  const loading = useSelector((state) => state.similarVideos.loading);
  const error = useSelector((state) => state.similarVideos.error);

  useEffect(() => {
    const channelName = Cookies.get('channel_name');

    const fetchSimilarVideos = async () => {
      dispatch(fetchSimilarVideosStart());
      try {
        let response;
        if (channelName) {
          response = await axios.post(`${baseUrl}/api/channel`, { channel_name: channelName });
        } else {
          response = await axios.get(`${baseUrl}/api/videos`);
        }

        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          let videos = response.data;
          if (!channelName) {
            videos = getRandomSubset(videos, 10);
          }
          dispatch(fetchSimilarVideosSuccess(videos));
        } else {
          console.error('Empty response or missing result data', response.data);
        }
      } catch (error) {
        console.error('Error fetching similar videos:', error.message);
        dispatch(fetchSimilarVideosFailure(error.message));
      }
    };

    fetchSimilarVideos();
  }, [dispatch]);

  const handleVideoClick = (url) => {
    setCurrentVideoUrl(url);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className="bg-white rounded-lg lg:p-6 lg:mx-8 p-6 mx-4">
        <h1 className="lg:text-3xl text-xl font-bold mb-4">Similar Videos</h1>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
          {similarVideos.map((video) => (
            <Card
              key={video.id}
              image_url={video.image_url}
              id={video.id}
              name={video.name}
              onClick={() => handleVideoClick(video.video_url)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

// Helper function to get a random subset of n items from an array
const getRandomSubset = (array, n) => {
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, n);
};

export default SimilarVideos;
