// src/redux/videoSlice.js
import { createSlice } from '@reduxjs/toolkit';

const videoSlice = createSlice({
    name: 'videos',
    initialState: {
        videos: [],
        loading: false,
        error: null
    },
    reducers: {
        fetchVideosStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchVideosSuccess: (state, action) => {
            state.loading = false;
            state.videos = action.payload;
        },
        fetchVideosFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchVideosStart, fetchVideosSuccess, fetchVideosFailure } = videoSlice.actions;
export default videoSlice.reducer;
