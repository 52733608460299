// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Card from '../pages/Card';
import Carousel from '../pages/Carousel';
import loadingImg from '../assets/bb.png'; // Import your loading image here
import Navbar from '../pages/Navbar';
import { baseUrl } from '../api/api';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import UserVideos from '../pages/UserVideos';
import Loader from '../pages/Loader';
import ReactPaginate from 'react-paginate';
import { fetchVideosStart, fetchVideosSuccess, fetchVideosFailure } from '../slice/videoSlice';
import errorImg from '../assets/error.webp'

function HomePage() {
  const [pageRange, setPageRange] = useState(window.innerWidth <= 768 ? 2 : 5); // Example for mobile breakpoint at 768px.
  const [currentPage, setCurrentPage] = useState(0); // start at page 0, because react-paginate is 0-indexed.
  const videosPerPage = 12;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videos = useSelector((state) => state.videos.videos);
  console.log(videos, "home]");
  const loading = useSelector((state) => state.videos.loading);
  const error = useSelector((state) => state.videos.error);

  useEffect(() => {
    const checkCookie = () => {
      const number = Cookies.get('number');
      if (!number) {
        navigate('/login');
      }
    };

    checkCookie();
  }, [navigate]);

  useEffect(() => {
    const fetchVideos = async () => {
      dispatch(fetchVideosStart());
      try {
        const response = await axios.get(`${baseUrl}/api/videos`);
        console.log(response, "response");
        dispatch(fetchVideosSuccess(response.data));
      } catch (err) {
        console.log(err, "error");
        dispatch(fetchVideosFailure(err.message));
      }
    };

    fetchVideos();
  }, [dispatch]);

  const handlePageClick = (data) => {  
    setCurrentPage(data.selected);
    const scrollHeight = window.innerHeight * 0.5;
    window.scrollTo({
      top: scrollHeight,
      
      behavior: 'smooth' // smooth scroll
    });
  };

  const indexOfLastVideo = (currentPage + 1) * videosPerPage;
  const indexOfFirstVideo = currentPage * videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <Loader />
        {/* <img src={loadingImg} alt='Loading...' className='w-[400px] h-[300px]' /> */}
      </div>
    );
  }

  if (error) {
    return (
      <div className='flex justify-center items-center h-screen '>
     <Loader/>
      </div>
    );
  }

  return (
    <div className='pb-6'>
      <Navbar />
      <div className='relative'>
        <Carousel/>
        {videos.length > 0 && <UserVideos />}
      </div>

      <h1 className='lg:mt-6 lg:mx-10 lg:px-6 lg:text-2xl text-xl mx-6 px-4 mt-10 text-black bg-[#e8dcf4] rounded-t-lg lg:pt-4 font-semibold '>EXPLORE VIDEOS...</h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 gap-3 shadow-lg bg-[#e8dcf4] lg:mx-10 lg:px-6 rounded-b-lg pb-4 px-2 mx-6">
        {currentVideos.map(video => (
          <Card
            key={video.id}
            image_url={video.image_url}
            id={video.id}
            name={video.name}
          />
        ))}
      </div>
      <ReactPaginate
        className='flex justify-center items-center gap-2 mb-6 mt-2'
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(videos.length / videosPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2} // Adjust the value as needed
        onPageChange={handlePageClick}
        containerClassName={'flex justify-center list-none p-0'}
        pageClassName={'mx-1 border border-gray-300 p-2 cursor-pointer rounded'}
        activeClassName={'border-blue-500 bg-blue-500 text-white'}
        pageLinkClassName={''}
        previousClassName={'mx-1 border border-gray-300 bg-white p-2 cursor-pointer rounded'}
        previousLinkClassName={''}
        nextClassName={'mx-1 border border-gray-300 p-2 bg-white cursor-pointer rounded'}
        nextLinkClassName={''}
        breakLinkClassName={''}
      />
    </div>
  );
}

export default HomePage;
