
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import axios from 'axios';
import SimilarVideos from './SimilarVideos';
// import loadingImg from '../assets/bb.png'
import Navbar from './Navbar';
// import { baseUrl } from '../api/api';
import Comments from './Comments';
import Loader from './Loader';
import { baseUrl } from '../api/api';

function VideoPlayer() {
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [videoViews,setVideoViews]=useState(false)

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/video/${id}`);
        console.log(response,"video player")
        const { video_url } = response.data.message[0] || response.data.message
        setVideoUrl(video_url);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching video:', error.message);
      } 
    };

    fetchVideoUrl();
  }, [id]);


  const handleVideoView=async()=>{
    if(!videoViews){
      try{
        const response=await axios.post(`${baseUrl}/api/views`,{videoId:id})
         console.log(response,"video views response======")
      }catch(error){
       console.error("Error adding video vies ",error)
      }
    }
  }

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <Loader/>
      </div>
    );
  }

  return (
    <div className='bg-[#C7A4E9]  h-full lg:h-full md:h-full'>
      <Navbar/>
      <div className="mt-4 flex justify-center bg-black lg:mx-8 mx-4 items-center lg:px-8 px-6 ">
        <ReactPlayer 
        url={videoUrl} controls={true}
        playing={true} width="100%"
        onEnded={handleVideoView}
         />
      </div>
      <Comments videoId={id}/>
      <SimilarVideos mainVideoId={id}/>
    </div>
  );
}

export default VideoPlayer;
