// src/slice/similarVideoSlice.js
import { createSlice } from '@reduxjs/toolkit';


export const similarVideoSlice = createSlice({
  name: 'similarVideos',
  initialState: {
    videos: [],
    loading: false,
    error: null,

  },
  reducers: {
    fetchSimilarVideosStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchSimilarVideosSuccess: (state, action) => {
      state.loading = false;
      state.videos = action.payload;
    },
    fetchSimilarVideosFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
   
  },
});

export const { fetchSimilarVideosStart, fetchSimilarVideosSuccess, fetchSimilarVideosFailure } = similarVideoSlice.actions;



export default similarVideoSlice.reducer;
