import React, { useEffect, useState } from 'react';
import axios from 'axios';
import loadingImg from '../assets/bb.png'; // Import your loading image here
import Cards from './Cards';
import Cookies from 'js-cookie';
import { baseUrl } from '../api/api';

function UploadedVideoStatus({ updateVideoCount }) {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msisdn, setMsisdn] = useState('');

  useEffect(() => {
    const msisdnCookie = Cookies.get('number');
    if (msisdnCookie) {
      setMsisdn(msisdnCookie);
      console.log(msisdnCookie, "====cookies");
    }
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      if (!msisdn) return; 
      try {
        const response = await axios.post(`${baseUrl}/status`, { msisdn });
        console.log(response, "response");
        setVideos(response.data);
        updateVideoCount(response.data.length);
        setLoading(false);
      } catch (err) {
        console.log(err, "error");
        setLoading(false);
      }
    };

    fetchVideos();
  }, [msisdn, updateVideoCount]);

  const handleRemoveVideo = async (id) => {
    console.log(`Attempting to delete video with id: ${id}`); // Add logging
    try {
      await axios.post(`${baseUrl}/status/delete`, { msisdn, id });
      setVideos(videos.filter(video => video.id !== id));
      updateVideoCount(videos.length - 1);
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <img src={loadingImg} alt='Loading...' className='w-[400px] h-[300px]' />
      </div>
    );
  }

  if (videos.length === 0) {
    return <p className="text-center mt-6">No New Notifications</p>;
  }

  return (
    <div className='pb-6 lg:mt-6 mt-6 overflow-y-auto max-h-[500px]'>
      <div className="flex flex-col lg:gap-4 gap-3 lg:mx-10 lg:px-6 pb-4 px-2 mx-6">
        {videos.map((video) => (
          <Cards
            key={video.id}
            id={video.id}
            status={video.STATUS}
            name={video.NAME}
            onRemove={() => handleRemoveVideo(video.id)}
          />
        ))}
      </div>
    </div>
  );
}

export default UploadedVideoStatus;
