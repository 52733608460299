import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserCard from '../pages/UserCard';
// import loadingImg from '../assets/bb.png'; // Import your loading image here
import Cookies from 'js-cookie';
// import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { baseUrl } from '../api/api';

function UserVideos() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [msisdn, setMsisdn] = useState("");
  // const navigate = useNavigate();

  useEffect(() => {
    const msisdn = Cookies.get('number');
    if (msisdn) {
      setMsisdn(msisdn);
      console.log(msisdn, "====cookies");
    }
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      if (!msisdn) return; // Wait for msisdn to be set before making the API call

      try {
        const response = await axios.post(`${baseUrl}/status/videos`, { msisdn: msisdn });
        console.log(response, "response user videos");
        setVideos(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err, "error");
        setLoading(false);
      }
    };

    fetchVideos();
  }, [msisdn]);

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <Loader/>
        {/* <img src={loadingImg} alt='Loading...' className='w-[400px] h-[300px]' /> */}
      </div>
    );
  }

  // Render UserVideos component only if there are videos available
  return (
    videos.length > 0 && (
      <div className='pb-6'>
        <h1 className='lg:mt-6 lg:mx-10 lg:px-6 lg:text-2xl text-xl mx-6 px-4 mt-10 text-black bg-[#e8dcf4] rounded-t-lg lg:pt-4 font-semibold'>My Videos</h1>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 lg:gap-4 gap-3 shadow-lg bg-[#e8dcf4] lg:mx-10 lg:px-6 rounded-b-lg pb-4 px-2 mx-6">
          {videos.map(video => (
            <UserCard
              key={video.id}
              image_url={video.thumbnail_url}
              // title={`Video ${video.id}`}
              id={video.id}
              name={video.name}
            />
          ))}
        </div>
      </div>
    )
  );
}

export default UserVideos;
