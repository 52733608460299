import React, { useState } from 'react';
import img from '../assets/bb.png';
import { useNavigate } from 'react-router-dom';
import { IoIosNotifications } from "react-icons/io";
import Sidebar from './Sidebar';
import { GiHamburgerMenu } from "react-icons/gi";
import CategorySidebar from './CategorySidebar';


function Navbar() {
  const [isCategorySidebarOpen, setIsCategorySidebarOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [videoCount, setVideoCount] = useState(0); // State for video count

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };

  const handleUploadClick = () => {
    navigate('/upload'); // Assuming the Dashboard component is mapped to this route
  };

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      setVideoCount(0); // Reset video count when closing sidebar
    }
    setIsSidebarOpen(!isSidebarOpen);
  };

  const updateVideoCount = (count) => {
    setVideoCount(count);
  };

  const toggleCategorySidebar=()=>{
    setIsCategorySidebarOpen(!isCategorySidebarOpen)
  }

  return (
    <>
      <div className='lg:pt-2 pt-1'>
        <div className='flex justify-between items-center bg-black shadow-2xl shadow-fuchsia-300 lg:mx-8 mx-4 rounded'>
         <div className='lg:pl-6 md:pl-8 pl-3'>
         <GiHamburgerMenu color="white" size={30}  onClick={toggleCategorySidebar}/>

         </div>
          <div className='flex-1 flex justify-center lg:ml-32 items-center cursor-pointer'>
            <img
              onClick={handleClick}
              src={img}
              alt="bubble tv"
              className='lg:w-[260px] lg:h-[90px] w-[230px] h-[100px] text-center'
            />
          </div>
          <div onClick={toggleSidebar}>
            <button className='lg:mr-6 relative'>
              <IoIosNotifications size={35} color='white' />
              {videoCount > 0 && (
                <span className='absolute top-0 right-0 rounded-full bg-red-600 w-5 h-5 flex items-center justify-center text-white text-xs'>
                  {videoCount}
                </span>
              )}
            </button>
          </div>
          <button
            onClick={handleUploadClick}
            className='bg-[#C7A4E9] text-white font-bold py-2 px-4 rounded-lg lg:mr-10 mr-4 ml-auto'
          >
            Upload
          </button>
        </div>
      </div>
      <CategorySidebar isOpen={isCategorySidebarOpen} toggleSidebar={toggleCategorySidebar}/>  
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} updateVideoCount={updateVideoCount} />
    </>
  );
}

export default Navbar;
